<template>
  <div class="row">
      <div class="col-md-12 top-space"></div>
      <div class="col-md-12 col-ms-12 soon-text-center top-space">
        <h1>Something Amazing is coming!</h1>
        <img alt="Vue logo" src="../assets/logo.png" class="soon-img">
        <h1>Laetus Entertainment</h1>
      </div>
  </div>
</template>

<script>
export default {

}
</script>

<style>

.top-space{
  height: 7vh;
}
.soon-img{
  height: 50vh;
}

.soon-text-center{
  text-align: center;
  color: white;
  text-shadow: 4px 4px 8px #112;
}
</style>